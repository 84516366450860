import Skeleton from "react-loading-skeleton";
import { useContext, useEffect, useState } from "react";
import { HomePageContext } from "/context/HomePageContext";
import dynamic from "next/dynamic";
import { getHomepageProducts } from "/lib/services/helpers/getHomepageProducts";
const ProductSecComponent = dynamic(() => import('./ProductSecComponent'));

const ProductSec = ({ inView, storeid = 1, mainCSSDiv = '', mainCSSDiv1 = '', mainCSSDiv2 = '', id, eventCommonObj }) => {
  const [{ isMobileType, cms_data }] = useContext(HomePageContext);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (inView) {
      const filters = {
        sku: { in: cms_data[`homepage_banner_productsection${id}_sku`]?.split(',') },
      };
      const response = async () => {
        setLoading(true);
        try {
          const tmp = await getHomepageProducts(filters, id);
          let tempprod = tmp?.data?.products?.items
          let sort_seq = cms_data[`homepage_banner_productsection${id}_banner_seq`]
          sort_seq = sort_seq ? sort_seq.split(",") : null
          tempprod.map((file, index) => {
            tempprod[index].sortid = (sort_seq && sort_seq[index] !== "undefined") ? sort_seq[index] : null
          });
          setProducts(tempprod)
          setLoading(false);
        } catch (error) {
          console.log('error in homepage product carousel', error);
          setProducts([]);
          setLoading(false);
        }
      };

      response();
    }
  }, [inView]);

  if (!inView || loading) {
    return <Skeleton count={1} height={isMobileType ? '37rem' : '38rem'} />
  }

  return (
    <ProductSecComponent
      productData={products}
      heading={cms_data[`homepage_banner_productsection${id}_title`]}
      link={cms_data[`homepage_banner_productsection${id}_link`]}
      inView={inView}
      linktext={cms_data[`homepage_banner_productsection${id}_linktext`]}
      storeid={storeid}
      mainCSSDiv={mainCSSDiv}
      mainCSSDiv1={mainCSSDiv1}
      mainCSSDiv2={mainCSSDiv2}
      id={id}
      eventCommonObj={eventCommonObj}
    />
  )
}

export default ProductSec;