import { useContext } from "react";
import { HomePageContext } from "/context/HomePageContext";
import { useInView } from "react-intersection-observer";
import DataHandler from "../../Skeleton";
import CheckOutExploreAllBlog from "../CheckOutExploreAllBlog";

const HomepageBlog = ({ prefixUrl, eventCommonObj }) => {
    const [{ isMobileType, cms_data }] = useContext(HomePageContext);
    const { ref: blogContainerRefSec, inView: blogContainerRefView } = useInView({
        threshold: 0,
        triggerOnce: true,
        rootMargin: "0px 0px 200px 0px"
    })

    return (
        <div ref={blogContainerRefSec} style={{ minHeight: isMobileType ? '20rem' : '32rem' }}>
            <DataHandler condition={blogContainerRefView} id='blog' height={isMobileType ? '20rem' : '32rem'}>
                <CheckOutExploreAllBlog prefixUrl={prefixUrl} eventCommonObj={eventCommonObj} />
            </DataHandler>
        </div>
    )
}

export default HomepageBlog;