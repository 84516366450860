import Image from "/components/CustomImage";
import styles from '../../Home.module.css'

const ImageArr = [
    {
        src: '/images/certifications-logo1.png',
        alt: 'Certifications logo',
        width: '98',
        height: '65',
    },
    {
        src: '/images/certifications-logo2.jpg',
        alt: 'Certifications logo',
        width: '65',
        height: '65',
    },
    {
        src: '/images/certifications-logo3.png',
        alt: 'Certifications logo',
        width: '94',
        height: '65',
    },
    {
        src: '/images/Picture4.png',
        alt: 'Certifications logo',
        width: '65',
        height: '65',
    },
    {
        src: '/images/Picture3.png',
        alt: 'Certifications logo',
        width: '65',
        height: '65',
    }
]

const OurCertificate = () => {
    return (
        <div className={styles.home_mobile_sec10}>
            <h2>Our Certifications</h2>
            <ul>
                {ImageArr?.map((item, index) => (
                    <li key={index}>
                        <Image loading='lazy' src={item.src} alt={item.alt} width={item.width} height={item.height} placeholder='blur' blurDataURL='/images/kama-placeholder.jpg' />
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default OurCertificate;