import { useContext } from "react";
import { HomePageContext } from "/context/HomePageContext";
import { useInView } from "react-intersection-observer";
import DataHandler from "../../Skeleton";
import { responsiveTestimonial } from "/lib/services/helpers/responsive";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import quotes_icon from '~/public/images/quotes-icon.inline.svg';
import Image from "/components/CustomImage";
import star_white from '~/public/images/star-white.inline.svg';

const Testimonials = ({ styles, testimonialList }) => {
    const [{ isMobileType, cms_data }] = useContext(HomePageContext);
    const { ref: testimonialDataSection, inView: testimonialDataSectionView } = useInView({
        threshold: 0,
        triggerOnce: true,
        rootMargin: "0px 0px 200px 0px"
    })

    return (
        <div ref={testimonialDataSection} style={{ minHeight: isMobileType ? '35rem' : '28rem' }}>
            <DataHandler condition={testimonialDataSectionView} height={isMobileType ? '35rem' : '28rem'}>
                <div className={styles.home_mobile_sec16}>
                    <h2>WE LOVE HAPPY CUSTOMERS</h2>
                    <> {!isMobileType ? (
                        <div className={`row ${styles["row"]}`}>
                            <Carousel
                                draggable
                                showDots={false}
                                responsive={responsiveTestimonial}
                                ssr // means to render carousel on server-side.
                                infinite
                                autoPlaySpeed={3000}
                                keyBoardControl
                                partialVisible
                                removeArrowOnDeviceType={["tablet", "mobile"]}
                                additionalTransfrom={-15}
                                itemClass={styles.carousel_item_padding}
                            >
                                {testimonialList.map((item, index) => (
                                    <div key={index}>
                                        <div className={styles.home_testi_sec16}>
                                            <div className={`col ${styles["col"]}`}>
                                                <abbr>
                                                    <img loading='lazy' src={quotes_icon} width={34} height={30}
                                                        alt="quotes icon" />
                                                </abbr>
                                                <p>{item.content}</p>
                                            </div>
                                            <div className={styles.home_testi_bottom}>
                                                <span>
                                                    <Image loading='lazy' src={item.image} alt={item.name} title={item.name} width={90} height={90}
                                                    />
                                                </span>
                                                <h4>{item.name}</h4>
                                                <ul>
                                                    <li>
                                                        {item.rating}{" "}
                                                        <b>
                                                            <img
                                                                loading='lazy'
                                                                src={star_white}
                                                                width={14}
                                                                height={13}
                                                                alt="star icon"
                                                            /></b>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Carousel>
                        </div>) : null}
                    </>
                    {isMobileType ? (
                        <div className={`row ${styles["row"]}`}>
                            {testimonialList.map((item, index) => (
                                <div key={index} className={`col-9 ${styles["col-9"]}`}>
                                    <div className={styles.home_testi_sec16}>
                                        <div className={`col ${styles["col"]}`}>
                                            <abbr>
                                                <img loading='lazy' src={quotes_icon} width={34} height={30}
                                                    alt="quotes icon" />
                                            </abbr>
                                            <p>{item.content}</p>
                                        </div>
                                        <div className={styles.home_testi_bottom}>
                                            <span>
                                                <Image loading='lazy' src={item.image} alt={item.name} title={item.name} width={90} height={90}
                                                />
                                            </span>
                                            <h4>{item.name}</h4>
                                            <ul>
                                                <li>
                                                    {item.rating}
                                                    <b>
                                                        <img
                                                            loading='lazy'
                                                            src={star_white}
                                                            width={14}
                                                            height={13}
                                                            alt="star icon"
                                                        /></b>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>) : null}
                </div>
            </DataHandler>
        </div>
    )
}

export default Testimonials;