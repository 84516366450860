const getBlogPost = async(blogIdsArray, key) => {
	const response = await fetch("/api/getposts", {
		method: "POST",
		body: JSON.stringify({ ids: blogIdsArray, key: key }),
		headers: {
			"Content-Type": "application/json",
		},
	});
	const data = await response.json();
	if (!response.ok) {
		return data;
	}
	return data;
}

export default getBlogPost