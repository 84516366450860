import { useCallback, useEffect, useRef } from "react";
import { createObserver } from "../../lib/ga/viewport";
import { BannerClick, BannerImpression } from "../../lib/ga";
import { getHyphenSeparatedString } from "../../lib/services/helpers/stringHelper";

const getPromotionName = (indexArr,cms_data) => {
    let promotionName = '';
    indexArr.map((index, i) => {
        promotionName += `${cms_data[`homepage_banner_promotion${index}_title`]}${i < indexArr.length - 1 ? ' | ' : ''}`
    });
    return promotionName;
}

const getCreativeName = (indexArr,cms_data) => {
    let creativeName = '';
    if (indexArr[0] == 9) {
        const catData = JSON.parse(cms_data[`homepage_banner_promotion9_text`]);
        catData.map((c, i) => creativeName += `${c.text}${i < catData.length - 1 ? ' | ' : ''}`)
    } else {
        indexArr.map((index, i) => {
            creativeName += `${cms_data[`homepage_banner_promotion${index}_text`]}${i < indexArr.length - 1 ? ' | ' : ''}`
        });
    }
    return creativeName;
}

export const useBannerGAEvent = ({ cms_data, banner, eventCommonObj}) => {
	const isHeroBannerImpressionSent = useRef(new Array(4).fill(false));
	const isBannerImpressionSent = useRef(new Array(12).fill(false));

	const createBannerIntersectionObserver = (index) => {
		createObserver(document.getElementById(`banner_${index}`), (entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting && !isBannerImpressionSent.current[index - 1]) {
					BannerImpression({
						bannerData: (index == 3 || index == 10) ? [
							{
								promotion_id: `banner_${index}`,
								promotion_name: getPromotionName([index],cms_data) || 'N/A',
								creative_name: getCreativeName([index], cms_data) || 'N/A',
								creative_slot: `${getHyphenSeparatedString(cms_data[`homepage_banner_promotion${index}_extra`] || cms_data[`homepage_banner_promotion${index}_title`])}_home_${index}`,
							},
							{
								promotion_id: `banner_${index + 1}`,
								promotion_name: getPromotionName([index + 1],cms_data) || 'N/A',
								creative_name: getCreativeName([index + 1], cms_data) || 'N/A',
								creative_slot: `${getHyphenSeparatedString(cms_data[`homepage_banner_promotion${index + 1}_extra`] || cms_data[`homepage_banner_promotion${index + 1}_title`])}_home_${index + 1}`,
							}
						] : [{
							promotion_id: `banner_${index}`,
							promotion_name: getPromotionName((index == 3 || index == 10) ? [index, index + 1] : [index],cms_data) || 'N/A',
							creative_name: getCreativeName((index == 3 || index == 10) ? [index, index + 1] : [index], cms_data) || 'N/A',
							creative_slot: `${getHyphenSeparatedString(cms_data[`homepage_banner_promotion${index}_extra`] || cms_data[`homepage_banner_promotion${index}_title`])}_home_${index}`,
						}],
						eventCommonObj
					});
					isBannerImpressionSent.current[index - 1] = true;
				}
			});
		});
	}

	useEffect(() => {
		// send hero1  banner image event
		sendHeroBannerImpressionGAEvent({ bannerItem: banner[0] });
		isHeroBannerImpressionSent.current[0] = true;

		// 5 Internal Promotion Banner Events
		[1, 2, 3, 9, 10].map((index) => {
			createBannerIntersectionObserver(index)
		})

	}, []);

	const sendHeroBannerImpressionGAEvent = ({ bannerItem }) => {
		if (!isHeroBannerImpressionSent.current[bannerItem.id]) {
			sendBannerImpressionGAEvent({ bannerItem })
			isHeroBannerImpressionSent.current[bannerItem.id] = true;
		}
	}

	const sendBannerImpressionGAEvent = useCallback(({bannerItem}) => {
		BannerImpression({
			bannerData: [{
				promotion_id: bannerItem.id || 'N/A',
				promotion_name: bannerItem.title || 'N/A',
				creative_name: bannerItem.text || 'N/A',
				creative_slot: `${bannerItem?.title?.toLowerCase?.()?.replaceAll?.(' ', '_') || 'banner'}_home_${bannerItem.id}`,
			}],
			eventCommonObj
		})
	},[cms_data,banner,eventCommonObj])

	const sendBannerClickGAEvent = useCallback(({bannerItem}) => {
		BannerClick({
			bannerData: {
				promotion_id: bannerItem.id || 'N/A',
				promotion_name: bannerItem.title || 'N/A',
				creative_name: bannerItem.text || 'N/A',
				creative_slot: `${bannerItem?.title?.toLowerCase?.()?.replaceAll?.(' ', '_') || 'banner'}_home_${bannerItem.id}`,
			},
			eventCommonObj
		})
	},[cms_data,eventCommonObj])

	return {
		sendBannerClickGAEvent,
		sendBannerImpressionGAEvent,
		sendHeroBannerImpressionGAEvent
	}
}