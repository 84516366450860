export const getHomepageProducts = async (filters, id) => {
    const response = await fetch("/api/products-homepage-query", {
        method: "POST",
        body: JSON.stringify({
            filters: filters,
            id: id
        }),
        headers: {
            "Content-Type": "application/json",
        },
    });

    const data = await response.json();

    if (!response.ok) {
        return data;
    }

    return data;
}
