import Link from "next/link";
import { useAnniversaryTimer } from "./useAnniversaryTimer";

const AnniversaryTimer = ({ duration = '', timerMsg = '', styles, websiteID = 1 }) => {
    const { timer } = useAnniversaryTimer({ duration });

    return (
        <Link href={websiteID == 1 ? "flash-sale.html" : "international/flash-sale.html"}>
            <div className={styles.anniversary_sec12}>
                <h2>
                    <abbr>{timerMsg}</abbr>
                </h2>
                <span className={styles.a_sec12_4}>{timer?.date}</span>:
                <span>{timer?.hour}</span>:<span className={styles.a_sec12_2}>{timer?.min}</span>:<span className={styles.a_sec12_3}>{timer?.sec}</span>
            </div>
        </Link>
    );
}

export default AnniversaryTimer