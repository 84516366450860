import { useContext } from "react";
import { useInView } from "react-intersection-observer";
import { HomePageContext } from "/context/HomePageContext";
import DataHandler from "../../Skeleton";
import CorporateGifting from ".";

const CorporateGiftingSection = ({ sendBannerClickGAEvent, eventCommonObj }) => {
    const [{ isMobileType, cms_data }] = useContext(HomePageContext);
    const { ref: corporateGiftingRef, inView: corporateGiftingView } = useInView({
        threshold: 0,
        triggerOnce: true,
        rootMargin: "0px 0px 200px 0px"
    })

    return (
        <div className='corporate-gifting-section' ref={corporateGiftingRef} style={{ minHeight: isMobileType ? '29rem' : '23rem' }}>
            <DataHandler condition={corporateGiftingView} height={isMobileType ? '29rem' : '23rem'}>
                <CorporateGifting sendBannerClickGAEvent={sendBannerClickGAEvent} eventCommonObj={eventCommonObj} />
            </DataHandler>
        </div>
    )
}

export default CorporateGiftingSection;