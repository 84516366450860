import { useContext, useEffect, useState } from "react";
import { HomePageContext } from "/context/HomePageContext";
import { getHomePageOrderSKU } from "/lib/magento-api";

export const useRecentPurchase = ({ session, storeid }) => {
    const [isApiCalled, setIsApiCalled] = useState(false);
	const [loading, setLoading] = useState(false);
	const [orderMainSkuData, setOrderMainSkuData] = useState([]);
	const [{ isMobileType }] = useContext(HomePageContext);

	function fetchCustomerSku(accessToken, website_id) {
		setLoading(true);
		const response = async () => {
			try {
				const tmp = await getHomePageOrderSKU(accessToken, website_id);
				if (tmp?.success && tmp?.data?.itemdata) {
					setIsApiCalled(true)
					setOrderMainSkuData(tmp.data.itemdata)
				}
				setLoading(false);
			} catch (error) {
				setLoading(false);
			}
		};
		response();
	}

	useEffect(() => {
		if (session?.accessToken && !isApiCalled) {
			fetchCustomerSku(session.accessToken, storeid)
		}
	}, [session, storeid, isApiCalled])

    return {
        loading,
        isMobileType,
        orderMainSkuData
    }
}