import { useContext } from "react";
import { HomePageContext } from "/context/HomePageContext";
import { useInView } from "react-intersection-observer";
import DataHandler from "../../Skeleton";
import Link from "next/link";

const ExploreAllIngredient = ({ styles, prefixUrl }) => {
    const [{ isMobileType, cms_data }] = useContext(HomePageContext);
    const { ref: ingredientContainerRefSec, inView: ingredientContainerRefView } = useInView({
        threshold: 0,
        triggerOnce: true,
        rootMargin: "0px 0px 200px 0px"
    })

    return (
        <div ref={ingredientContainerRefSec} style={{ minHeight: isMobileType ? '37rem' : '20rem' }}>
            <div className={styles.home_mobile_sec8}>
                <DataHandler condition={ingredientContainerRefView} height={isMobileType ? '37rem' : '20rem'}>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: cms_data.homepage_banner_others_ingredients,
                        }}
                    />
                </DataHandler>
                <abbr>
                    <Link href={`${prefixUrl}/ingredient`}>
                        Explore All Ingredients
                    </Link>
                </abbr>
            </div>
        </div>
    );
}

export default ExploreAllIngredient;