import Skeleton from "react-loading-skeleton";
import dynamic from 'next/dynamic';
import { useRecentPurchase } from "./useRecentPurchase";
const RecentPurchaseComponent = dynamic(() => import('./RecentPurchaseComponent'));

const RecentPurchase = ({ inView, session, storeid }) => {
	const {
		loading,
		isMobileType,
		orderMainSkuData
	} = useRecentPurchase({ session, storeid })


	if (!inView || loading) {
		return <Skeleton count={1} height={session?.user ? (isMobileType ? '37rem' : '38rem') : 0} />
	}

	if (orderMainSkuData?.length == 0 || !session?.user) {
		return null;
	}

	return (
		<RecentPurchaseComponent
			orderMainSkuData={orderMainSkuData}
			session={session}
			storeid={storeid}
		/>
	)
}

export default RecentPurchase;