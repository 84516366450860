import DataHandler from "../../Skeleton";
import { useContext } from "react";
import { HomePageContext } from "../../../../context/HomePageContext";
import dynamic from "next/dynamic";
const TwoBannerComponent = dynamic(() => import('./TwoBannerComponent'));

const TwoBannerSection = ({ sendBannerClickGAEvent, inView, id, mainCSS, mainCSS2 }) => {
	const [{ isMobileType, cms_data }] = useContext(HomePageContext);

	return (
		<DataHandler condition={inView} height={isMobileType ? '59rem' : '40rem'}>
			<TwoBannerComponent
				sendBannerClickGAEvent={sendBannerClickGAEvent}
				heading={cms_data?.[`homepage_banner_promotion${id}_extra`]}
				link={cms_data?.[`homepage_banner_promotion${id}_link`]}
				imageURL={`${cms_data?.base_media_url}homepage_images/${cms_data?.[`homepage_banner_promotion${id}_image`]}`}
				subHeading={cms_data?.[`homepage_banner_promotion${id}_title`]}
				description={cms_data?.[`homepage_banner_promotion${id}_text`]}
				link2={cms_data?.[`homepage_banner_promotion${id + 1}_link`]}
				imageURL2={`${cms_data?.base_media_url}homepage_images/${cms_data?.[`homepage_banner_promotion${id + 1}_image`]}`}
				subHeading2={cms_data?.[`homepage_banner_promotion${id + 1}_title`]}
				description2={cms_data?.[`homepage_banner_promotion${id + 1}_text`]}
				id={id}
				mainCSS={mainCSS}
				mainCSS2={mainCSS2}
			/>
		</DataHandler>
	)
}

export default TwoBannerSection;