import DataHandler from "../../Skeleton/index"
import { useContext } from "react";
import { HomePageContext } from "../../../../context/HomePageContext";
import dynamic from "next/dynamic";
const VideoBannerComponent = dynamic(() => import('./VideoBannerComponent'));

const VideoBanner = ({ sendBannerClickGAEvent, mainCSS, inView, normalCSS, id }) => {
	const [{ isMobileType, cms_data }] = useContext(HomePageContext);

	return (
		<DataHandler condition={inView} height={isMobileType ? '25rem' : '24rem'}>
			<VideoBannerComponent
				isMobileType={isMobileType}
				sendBannerClickGAEvent={sendBannerClickGAEvent}
				mainCSS={mainCSS}
				normalCSS={normalCSS}
				promotionLink={cms_data?.[`homepage_banner_promotion${id}_link`]}
				promotionImage={cms_data?.[`homepage_banner_promotion${id}_image`]}
				promotionMobileImage={cms_data?.[`homepage_banner_promotion${id}_mobile_image`]}
				altTag={cms_data?.[`homepage_banner_promotion${id}_title`]}
				baseURL={cms_data.base_media_url}
				title={cms_data?.[`homepage_banner_promotion${id}_text`]}
				text={cms_data?.[`homepage_banner_promotion${id}_text`]}
				mobileTitle={cms_data?.[`homepage_banner_promotion${id}_mobile_title`]}
				mobileText={cms_data?.[`homepage_banner_promotion${id}_mobile_text`]}
				id={id}
			/>

		</DataHandler>
	)
}

export default VideoBanner;