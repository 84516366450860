import { useContext, useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import Skeleton from "react-loading-skeleton";
import { HomePageContext } from '/context/HomePageContext';
import getBlogPost from '/lib/apis/getBlogPost';
const CheckOutExploreAllBlogComponent = dynamic(() => import('./CheckOutExploreAllBlogComponent'));

const CheckOutExploreAllBlog = ({ prefixUrl, eventCommonObj }) => {
	const [{ isMobileType, cms_data }] = useContext(HomePageContext);
	const [HomeBlog, setHomeBlog] = useState([]);
	const [loading, setLoading] = useState(false)

	function fetchBlogs(blogIdsArray) {
		setLoading(true);
		const response = async () => {
			try {
				const tmp = await getBlogPost(blogIdsArray, 'home');
				if (tmp?.edges) {
					setHomeBlog(tmp?.edges);
				}
				setLoading(false);
			} catch (error) {
				setLoading(false);
				setHomeBlog([]);
			}
		};
		response();
	}

	useEffect(() => {
		const array = cms_data?.homepage_banner_promotion13_text;
		fetchBlogs(array)
	}, [cms_data])

	if (loading) {
		return <Skeleton count={1} height={isMobileType ? '20rem' : '32rem'} />
	}

	if (HomeBlog?.length == 0) {
		return null;
	}

	return (
		<CheckOutExploreAllBlogComponent
			isMobileType={isMobileType}
			heading={cms_data.homepage_banner_promotion13_title}
			HomeBlog={HomeBlog}
			prefixUrl={prefixUrl}
			eventCommonObj={eventCommonObj}
		/>
	)
}

export default CheckOutExploreAllBlog;