import dynamic from 'next/dynamic';

const CorporateGiftingComponent = dynamic(() => import('./CorporateGiftingComponent'));

const CorporateGifting = ({ sendBannerClickGAEvent, eventCommonObj }) => {
    return (
        <CorporateGiftingComponent
            sendBannerClickGAEvent={sendBannerClickGAEvent}
            eventCommonObj={eventCommonObj}
        />
    )
}

export default CorporateGifting