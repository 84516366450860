import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";

const DataHandler = ({ condition , children, height }) => {
  const [showContent , setShowContent] = useState(false);
  useEffect(() => {
    if(condition){
      setShowContent(true);
    }
  },[condition]);

  if (!showContent || !condition ) {
    return <Skeleton count={1} height={height} />;
  } 
  return <>{children}</>;
};

export default DataHandler;
