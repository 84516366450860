import { useEffect, useRef, useState } from "react";
import { getDeadTime } from "/lib/services/helpers/getDeadTime";
import { getTimeRemaining } from "/lib/services/helpers/getTimeRemaining";

export const useAnniversaryTimer = ({duration}) => {
    const [timer, setTimer] = useState({
        date: 0,
        hour: 0,
        min: 0,
        sec: 0
    })
    const Ref = useRef(null);

    // We can use useEffect so that when the component
    // mount the timer will start as soon as possible

    // We put empty array to act as componentDid
    // mount only
    useEffect(() => {
        clearTimer(getDeadTime(duration));
    }, [duration]);

    const startTimer = (e) => {
        let { total, dates, hours, minutes, seconds } = getTimeRemaining(e);
        if (total >= 0) {
            // update the timer
            // check if less than 10 then we need to 
            // add '0' at the beginning of the variable
            setTimer({
                date: dates > 9 ? dates : `0${dates}`,
                hour: hours > 9 ? hours : `0${hours}`,
                min: minutes > 9 ? minutes : `0${minutes}`,
                sec: seconds > 9 ? seconds : `0${seconds}`
            })
        }
    }

    const clearTimer = (e) => {
        // If you adjust it you should also need to
        // adjust the Endtime formula we are about
        // to code next   
        setTimer({
            date: 0,
            hour: 0,
            min: 0,
            sec: 0
        })

        // If you try to remove this line the 
        // updating of timer Variable will be
        // after 1000ms or 1sec
        if (Ref.current) clearInterval(Ref.current);
        const id = setInterval(() => {
            startTimer(e);
        }, 1000)
        Ref.current = id;
    }

    return {
        timer
    }
}